$drop-shadow:rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;


.l-property-search {
  font-family: $g-font-base;
  padding: 0 0 50px;
  max-width: 600px;
  margin: 0 auto;

  @include bp-sm {
    padding: 50px 0 50px;
  }

  @include bp-md {
    max-width: 100%;
  }

  &__heading {
    font-weight: normal;
    text-align: center;
    font-size: 15px;
    padding: 0 15px;    
    
    @include bp-md {
      padding: 0 100px;
      font-size: 18px;
    }
  }

  &__input {
    border-color: transparent;    
    align-items: center;
    font-weight: normal;

    font-size: 14px;
    height: 40px;

    @include bp-sm {
      font-size: 15px;
      height: 55px;
    }
  }

  &__input-prefix {
    margin: 0 12px 0 8px;
    display: flex;
    align-items: center;
  }

  &__input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__lookup-btn {
    margin-left: 10px;
    border-color: transparent;
    padding: 0 20px;    
    font-size: 14px;
    height: 40px;
    width: 80px;

    @include bp-sm {
      font-size: 15px;
      height: 55px;
      width: 220px;
      padding: 0 40px;
    }

    &:focus-visible {
      outline: 1px solid #68d9c4 !important;
      outline-offset: 1px !important;
    }
  }

  &__suggestions {
    position: absolute;
    left: 0;
    list-style: none;
    padding: none;
    margin: none;
    z-index: 3;
    background-color: $g-white;
    width: 100%;
    border-radius: 6px;
    max-height: 250px;
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    top: 50px;

    @include bp-sm {
      top: 65px;
    }
  }

  &__suggestion-item {
    > button {
      min-height: 50px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      cursor: pointer;
      width: 100%;
    }

    &:hover {
      background-color: $g-misc-color-2;
    }

    &--error {
      cursor: default;
      min-height: 50px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      width: 100%;

      &:hover {
        background-color: $g-white;
      }
    }

    .h-item {
      text-align: left;
    }
  }

  &__property,
  &__estimates {
    margin-top: 30px;
    display: flex;
    position: relative;
    flex-direction: column;

    @include bp-md {
      flex-direction: row;
    }
  }

  &__property-info {
    background-color: $g-white;
    border-radius: 6px;
    box-shadow: $drop-shadow;
    width: 100%;

    @include bp-md {
      width: 65%;
    }
  }

  &__estimates-body {
    width: 100%;
    background-color: $g-white;
    border-radius: 6px;
    box-shadow: $drop-shadow;
  }

  &__estimates-details {
    padding: 20px 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h3 {
      margin-bottom: 0;
      padding: 0;
      font-weight: 600;
      font-size: 20px;
      display: flex;
      align-items: center;
    }

    p {
      margin-top: 5px;

      @include bp-sm {
        margin-top: 0;
      }
    }
  }

  &__estimates-details-flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @include bp-sm {
      align-items: center;
    }
  }

  &__estimates-form {
    display: flex;
    margin: 0;
    padding: 20px;
    width: 100%;

    form {
      width: 100%;
      padding: 12px 20px 20px;
      border-radius: 6px;
      background-color: #fafbfc;
    }
  }

  &__estimates-confidence {
    font-size: 28px;
    padding-bottom: 0;
    margin: 0;
    font-weight: 500;
    padding-left: 4px;
  }

  &__estimates-half-fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include bp-sm {
      flex-direction: row;
      justify-content: space-between;
    }

    div.h-field {
      width: 100%;

      @include bp-sm {
        width: 50%;
      }
    }

    span.h-field {
      align-items: center;
      justify-content: center;
      display: flex;
      margin: 10px auto;

      @include bp-sm {
        margin: 0 10px;
        height: 50px;
      }
    }
  }

  &__property-photo {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 6px 6px 0 0;
    background: rgb(0,54,50);
    background: radial-gradient(circle, rgba(0, 54, 50, .4) 0%, rgba(0, 47, 43, .5) 100%);
    min-height: 200px;
    max-height: 260px;

    @include bp-md {
      min-height: 340px;
      max-height: 400px;
    }
  }
  
  &__property-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__property-form {
    border-radius: 6px;
    padding: 20px;
    box-shadow: $drop-shadow;
    width: 100%;
    margin-top: 15px;
    background-color: $g-white;

    @include bp-md {
      width: 35%;
      margin-left: 20px;
      margin-top: 0;
    }
  }

  &__details {
    padding: 20px 20px 0;
    display: flex;
    flex-direction: column;

    @include bp-md {
      justify-content: space-between;
      flex-direction: row;
    }    

    h3 {
      color: $g-brand-color-1;
      margin-bottom: 5px;
      padding: 0;
      font-weight: 600;
    }

    p {
      display: flex;
      align-items: center;
      padding: 0;
      font-size: 14px;
      margin-bottom: 15px;

      @include bp-md {
        font-size: 15px;
        margin-bottom: 30px;
      }

      svg {
        margin-right: 8px;
      }
    }
  }

  &__badge-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;

    @include bp-md {
      margin-bottom: 0;
    }

    &--light {
      margin-top: 5px;

      .l-property-search__badge {
        background-color: $g-white;
        border: 1px solid #eaeaea;
        margin-top: 10px;

        @include bp-sm {
          margin-top: 0;
        }
      }
    }
  }

  &__badge {
    border-radius: 30px;
    padding: 4px 16px;
    background-color: $g-misc-color-1;
    margin-right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 15px;
      margin-right: 5px;
      width: 15px;
    }
  }

  &__score-container {
    display: flex;
    justify-content: center;
    align-items: center;

    @include bp-md {
      justify-content: flex-start;
    }
  }

  &__score {
    display: flex;
    align-items: center;
    margin: 15px 5px 0;

    @include bp-md {
      margin: 0 0 0 20px;
    }

    > span {
      text-align: right;
    }
  }

  &__score-value {
    position: relative;
    height: 64px;
    width: 64px;
    margin-left: 5px;

    .ant-icon {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      color: $g-white;
      font-size: 20px;
      font-weight: 500;
    }
  }

  &__other-details {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 20px;
    list-style: none;
  }

  &__other-detail-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding-right: 30px;

    @include bp-xs {
      width: 50%;
    }

    @include bp-sm {
      width: 32%;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &__other-detail-label {
    text-transform: uppercase;
    font-size: 12px;
    color: $g-misc-color-3;
  }

  &__other-detail-value {
    font-size: 15px;
  }

  &__input-details {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__input-detail-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    min-height: 52px;
    border-bottom: 1px solid $g-misc-color-1;

    > span {
      margin-right: 15px;
    }

    .h-margin--zero {
      margin: 0;
    }

    .h-color--blue {
      background-color: $g-misc-color-4;
    }
  }

  // Form

  &__field {
    display: flex;
    flex-direction: column;
    padding: 10px 0 5px;
    align-items: flex-start;
    width: 100%;

    .h-emp {
      font-size: 16px !important;
      margin-left: 5px;
      margin-top: 5px;

      @include bp-xs {
        font-size: 18px !important
      }
    }

    &--bordered {
      border: solid #eee 1px;
      border-width: 1px 0 !important;
      margin: 12px 0 21px;
      padding: 10px 0 13px;

      input {
        cursor: pointer;
        height: 37px;
      }
    }

    .ant-input-number-affix-wrapper, .ant-input-number-input, .ant-select {
      align-items: center;
      height: 39px;
      width: 100%;
    }

    .ant-input-number-affix-wrapper input.ant-input-number-input {
      font-size: 18px;
      font-weight: 600;
    }

    > div {
      width: 100%;
      flex: 1 1 100%;

      .error {
        color: $g-red;
        margin-left: 5px;
      }
    }

    .ant-input-number-affix-wrapper {
      height: 47px;
    }

    > label {
      padding-right: 15px;
      padding-left: 5px;
      width: 100%;
      display: flex;
      align-items: center;
      height: 30px;
    }
  }

  &__form {
    position: relative;
  }

  &__preview-modal {
    position: relative;
  }

  &__preview-btn-group {    
    display: flex;
    justify-content: flex-end;
    margin-right: 8px;
    
    @include bp-lg {
      position: absolute;
      top: 8px;
      right: 15px;
    }

    .ant-btn {
      margin-left: 8px;
    }
  }

  &__preview-iframe {
    width: 100%;
    height: calc(100vh - 200px);
  }

  &__avm-btn-group {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;

    @include bp-sm {
      flex-direction: row;
      justify-content: space-between;
      row-gap: 0;
    }
  }
}

.main-tabs {
  margin-bottom: 5px;
}