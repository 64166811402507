.l-folder-select {
  padding: 15px 20px 20px;

  &__search {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 15px;
    
    .ant-btn {
      margin-left: 8px;

      @include bp-sm {
        width: 100px;
      }
    }

    .ant-input {
      height: 32px;
      width: 450px;
      font-size: 13px !important;

      @include bp-sm {
        font-size: 14px !important;
      }
    }
  }

  &__sort {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;

    .ant-radio-group {
      display: flex;      
      margin-right: 8px;

      @include bp-sm {
        margin-right: 20px
      }
    }

    .ant-radio-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      font-size: 13px;

      @include bp-sm {
        width: 125px;
        font-size: 14px;
      }
    }
  }
  
  &__bread-crumbs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    width: 100%;
    border-top: 1px solid $g-misc-color-1;
    padding-top: 15px;

    @include bp-sm {
      flex-direction: row;
    }
  }

  &__bread-crumbs-trail {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;

    @include bp-sm {
      margin: 0 0 0 20px;
    }
  }

  &__bread-crumbs-trail-item {
    display: flex;
    align-items: center;
    margin: 0 15px 0 0;
    min-height: 32px;

    svg {
      margin-right: 8px;
      color: $g-brand-color-2;
    }

    &:nth-child(2) {
      svg {
        display: none;
      }  
    }

    &:first-child {
      svg {
        display: none;
      }
    }

    @include bp-sm {
      &:nth-child(2) {
        svg {
          display: block;
        }  
      }
    }
  }

  &__bread-crumbs-trail-btn {
    color: $g-misc-color-5;
    font-size: 13px;
    
    @include bp-sm {
      font-size: 14px;
    }

    &:hover {
      color: $g-brand-color-1;
    }

    strong {
      color: $g-misc-color-6;
    }
  }

  &__reset-btn {
    margin-left: 8px;
    
    @include bp-sm {
      margin-left: auto !important;
      width: 140px !important;
    }
  }

  &__folder-name {
    svg {
      opacity: 0.7;
      margin-right: 10px;
    }
  }

  &__submit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 0 0;

    .ant-btn {
      width: 150px;
      height: 40px;
    }
    
    p {
      margin: 0 20px 0 0;
      color: $g-misc-color-7;

      strong {
        font-weight: 500;
        margin-left: 10px;
      }
    }
  }

  &__table-container {
    overflow: auto;
    height: calc(100vh - 400px);

    @include bp-sm {
      height: 400px;
    }

    .ant-table-row {
      cursor: pointer;
      
      &:hover {
        td {
          background-color: $g-misc-color-2 !important;
        }
      }
    }
  }

  &__options-btn {
    display: flex;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    
    &:hover {
      background-color: $g-misc-color-8;
    }

    align-items: center;
    justify-content: center;
    margin-left: auto;
  }

  &__batch-loading {
    height: 100px;
    position: relative;
  }
}

.h-text-decoration--none {
  &:hover {
    text-decoration: none;
  }
}