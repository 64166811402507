.l-layout {
  height: 100% !important;
  min-height: 100%;
  overflow-y: scroll;

  &__header {
    background-color: $g-white;
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;    
    padding: 0 15px;

    @include bp-sm {
      padding: 0 50px;
    }
  }

  &__header-logo {
    display: flex;
    align-items: center;
  }

  &__header-logo-image {
    height: 38px;
    width: 38px;
    display: block;
    margin-right: 12px;
  }

  &__header-logo-app {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5em;
    letter-spacing: 1px;
  }

  &__header-user {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 65px;
  }
}

.l-main {
  width: 100%;
  max-width: 1264px;
  margin: 0 auto;
  padding: 15px;
}