.full-page-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 5;
  background-color: $g-white;
  opacity: 0.8;
  border-radius: 6px;

  &__message {
    font-size: 15px;
    margin-top: 4px;
    font-weight: 600;
    color: $g-black;
  }
}
