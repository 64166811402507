//////////////////////////////////
// Adds staggered animations    //
//////////////////////////////////

@mixin animationDelay($delay) {
  animation-delay: $delay;
}

$interval: 0.05s;
$classesToAdd: 24;

@for $i from 1 to $classesToAdd + 1 {
  ._#{$i} {
    @include animationDelay($i * $interval);
  }
}

@mixin anim-fade-default {
  opacity: 0;
  transition: transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    opacity 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  &.animated {
    opacity: 1;
  }
}

@mixin anim-top-default {
  opacity: 0;
  transform: translateY(-20px);
  transition: transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    opacity 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  &.animated {
    transform: translateX(0);
    opacity: 1;
  }
}

@mixin anim-bottom-default {
  opacity: 0;
  transform: translateY(20px);
  transition: $g-trans-base-2;
  &.animated {
    transform: translateX(0);
    opacity: 1;
  }
}

@mixin anim-left-default {
  transform: translateX(-30px);
  transition: transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    opacity 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  &.animated {
    transform: translateX(0);
  }
}

@mixin anim-right-default {
  transform: translateX(30px);
  transition: transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    opacity 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  &.animated {
    transform: translateX(0);
  }
}

@mixin underline-general(
  $tickness,
  $color,
  $transform-origin,
  $transform-origin-hover
) {
  position: relative;
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.75s ease-out;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    right: 0;
    height: $tickness;
    background: $color;
    transition: transform 0.25s ease-in;
    transform: scaleX(0);
    transform-origin: $transform-origin;
  }
  &:hover {
    &:before {
      transform: scale(1);
      transform-origin: $transform-origin-hover;
    }
  }
}

@mixin underline-special {
  position: relative;
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.75s ease-out;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    right: 0;
    height: 2px;
    background: $g-brand-color-2;
    transition: transform 0.25s ease-in;
    transform: scaleX(0);
    transform-origin: right center;
  }
  &:hover {
    &:before {
      transform: scale(1);
      transform-origin: left center;
    }
  }
}

// Use underline-special above first
@mixin underline-special-reverse {
  &:before {
    transform: scale(1);
    transform-origin: left center;
  }
  &:hover {
    &:before {
      transform: scaleX(0);
      transform-origin: right center;
    }
  }
}

@keyframes rotatingEndless {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin rotating200 {
  animation: rotatingEndless 200s linear infinite;
}
