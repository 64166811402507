.ant-picker-header-view {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-picker {
  height: 40px;
}

.ant-modal-content {
  padding: 0px !important;

  .ant-modal-header {
    padding: 20px 24px;
    border-bottom: 1px solid $g-additional-color-3;
    align-items: center;
  }

  .ant-modal-body {
    padding: 20px 24px;
  }
  .ant-modal-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
  }

  .ant-modal-close {
    top: 24px;
    height: 24px;
    width: 24px;
    svg {
      height: 18px;
      width: 18px;
    }
  }
}

.ant-input {
  @include bpm-xs {
    font-size: 16px !important;
  }
}

.ant-picker-input,
.ant-input-number-input-wrap {
  input {
    @include bpm-xs {
      font-size: 16px !important;
    }
  }
}

.ant-modal-title {
  @include bpm-xs {
    font-size: 20px !important;
  }
}
