.disclaimer-modal {
  user-select: text;
  ul,
  ol {
    padding-left: 20px;
  }
}

.corelogic-disc-tooltip {
  .ant-table-thead {
    display: none;
  }
  .ant-table-content {
    cursor: pointer;
  }
}
.corelogic-tooltip {
  max-width: 500px;
  ul {
    padding-left: 20px;
  }
}

.corelogic-disc-op-100 {
  opacity: 100;
}

.consumer-disclaier-trigger {
  font-size: 15px;
  padding: 0;
  color: #00b2a3;
  margin-left: 5px;
}
